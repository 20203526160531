import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  signout: false,
  currentToken: null,
  logged: null,
  unauthorized: false,
  registerCode: null,
  useInfo: null,
  preferences: null,
  apartmentMode: null,
  initialApartmentMode: null,
  meterMode: null,
  initialMeterMode: null,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    signin(state, action) {
      localStorage.setItem("user_token", action.payload);
      localStorage.setItem("banner", "active");
      state.currentToken = action.payload;
      state.logged = true;
    },
    signout(state) {
      state.signout = true;
    },
    getFrom() {
      /* return JSON.parse(localStorage.getItem("from")); */
    },
    user() {
      return JSON.parse(localStorage.getItem("user_info"));
    },
    setFrom(state, action) {
      localStorage.setItem("from", JSON.stringify(action.payload));
    },
    isLogged(state) {
      const newToken = localStorage.getItem("user_token");
      const userInfo = localStorage.getItem("user_info");
      if (newToken) {
        state.logged = true;
      } else {
        state.logged = false;
      }
    },
    logout(state) {
      state.logged = null;
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_preferences");
      /*     localStorage.setItem("banner", "inactive"); */
      state.unauthorized = true;
    },
    setCode(state, action) {
      debugger;
      state.registerCode = action.payload;
    },
    setApartmentMode(state, action) {
      state.apartmentMode = action.payload;
    },
    setMeterMode(state, action) {
      state.meterMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.authEndpoints.signout.matchFulfilled,
      (state, action) => {
        localStorage.removeItem("user_token");
        localStorage.removeItem("user_info");
        localStorage.removeItem("from");
        localStorage.removeItem("user_preferences");

        state.currentToken = null;
        state.logged = null;
        state.signout = false;
      }
    );
    builder.addMatcher(
      store.authEndpoints.getMe.matchFulfilled,
      (state, action) => {
        state.useInfo = action.payload.data;
        state.preferences = action.payload.data.preferences;
        state.meterMode = action.payload.data.preferences.meter_mode ?? "card";
        state.apartmentMode =
          action.payload.data.preferences.apartment_mode ?? "card";
        state.initialApartmentMode =
          action.payload.data.preferences.apartment_mode ?? "card";
        state.initialMeterMode =
          action.payload.data.preferences.meter_mode ?? "card";
      }
    );
  },
});

//TODO: try to do it better
export const getUserToken = () => {
  return localStorage.getItem("user_token");
};
export const actions = authSlice.actions;
export default authSlice.reducer;
