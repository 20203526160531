import { useEffect, useState } from "react";
import { StyledDataGrid } from "../utils/StyledDataGrid";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import theme from "../theme";
const WaterTable = () => {
  const data = useSelector((state) => state?.meterSlice?.readings);
  const [page, setPage] = useState(1);
  useEffect(() => {
    console.log("data", data);
  }, [data]);
  const { t } = useTranslation();
  const columns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("dt_n", { val: new Date(params.row.messageDate) })}</>;
      },
    },

    {
      field: "strValue",
      headerName: t("volume"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    },
  ];
  const rows = data?.map((item, i) => ({
    id: i,
    messageDate: item?.values?.read_time ?? "",
    strValue:
      item?.values?.values?.value?.last_reading?.value !== null
        ? `${t("number", {
            val: item?.values?.values?.value?.last_reading?.value,
          })}`
        : "--",
    unit: item?.values?.values?.value?.last_reading?.unit,
  }));
  return (
    <Box sx={{ flexGrow: 1, width: "98%", mt: 2, mr: 2, height: "80%" }}>
      <Typography
        sx={{ mb: 1 }}
        color={theme.palette.primary.main}
        variant="h6"
      >
        {t("readings")}
      </Typography>
      <StyledDataGrid
        onSortModelChange={(model) => console.log("test")}
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        pageSize={[10]}
        paginationMode="client"
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        rowCount={data?.length}
        getRowHeight={() => "auto"}

        // sortingMode="server"
        // onSortModelChange={handleSortModelChange}

        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default WaterTable;
