import React from "react";
import { MuiColorInput } from "mui-color-input";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";

const ColorPickerWidget = () => {
  const [primary, setPrimary] = useState("#ffffff");
  const [secondary, setSecondary] = useState("#ffffff");

  const primaryChange = (newValue) => {
    setPrimary(newValue);
  };
  const secondaryChange = (newValue) => {
    setSecondary(newValue);
  };

  return (
    <Box>
      <MuiColorInput
        format="hex"
        label="Primary"
        value={primary}
        onChange={primaryChange}
      />
      <MuiColorInput
        format="hex"
        label="Secondary"
        value={secondary}
        onChange={secondaryChange}
      />
    </Box>
  );
};
export default ColorPickerWidget;
