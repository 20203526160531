import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AcquaImage from "../img/acqua.jpeg";
import HCA from "../img/hca.jpg";
import Calore from "../img/calore.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { actions } from "../redux/slices/meterSlice";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MeterCard({
  type,
  date,
  value,
  unit,
  serial,
  coldValue,
  heatValue,
  colorUnit,
  colorDate,
  appId,
  meterId,
  meter,
  maxWidth,
  room = null,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const imgSelector = (type) => {
    if (type == "07") {
      return AcquaImage;
    }
    if (type == "13") {
      return Calore;
    }
    if (type == "08") {
      return HCA;
    }
  };
  const titleSelector = (type) => {
    if (type == "07") {
      return <Typography>{`Contatore di acqua: ${serial}`}</Typography>;
    }
    if (type == "13") {
      return <Typography>{`Contatore di calore: ${serial}`}</Typography>;
    }
    if (type == "08") {
      return <Typography>{`HCA: ${serial}`}</Typography>;
    }
  };

  const onCardClick = () => {
    navigate(`/meters/${appId}/${meterId}`);
    dispatch(actions.setMeterType(type));
    dispatch(actions.setMeterShow(meter));
    localStorage.setItem("meterShow", JSON.stringify(meter));
    localStorage.setItem("meterType", type);
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: maxWidth,
        m: 1,
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
      }}
      onClick={onCardClick}
    >
      <CardHeader
        /*        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        } */
        title={titleSelector(type)}
        subheader={`Data dell'ultimo aggiornamento: ${t("dt", {
          val: new Date(type !== "13" ? date : colorDate),
        })}`}
      />
      <CardMedia
        component="img"
        height="194"
        image={imgSelector(type)}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {type !== "13" &&
            `${t("last_reading_value")}: ${
              value
                ? t("number", {
                    val: value,
                  })
                : "NA"
            } ${unit ?? ""}`}
          {type == "13" &&
            `${t("calories")}: ${
              heatValue
                ? t("number", {
                    val: heatValue / 1000,
                  })
                : "NA"
            } kWh`}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {type == "13" &&
            `${t("frigories")}: ${
              coldValue
                ? t("number", {
                    val: coldValue / 1000,
                  })
                : "NA"
            } kWh`}
        </Typography>
        {room && (
          <Typography variant="body2" color="text.secondary">{`${t(
            "room"
          )}: ${room} `}</Typography>
        )}
      </CardContent>
      <CardActions disableSpacing>
        {/*       <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {/* <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and
            set aside for 10 minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet
            over medium-high heat. Add chicken, shrimp and chorizo, and cook,
            stirring occasionally until lightly browned, 6 to 8 minutes.
            Transfer shrimp to a large plate and set aside, leaving chicken and
            chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes,
            onion, salt and pepper, and cook, stirring often until thickened and
            fragrant, about 10 minutes. Add saffron broth and remaining 4 1/2
            cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and
            peppers, and cook without stirring, until most of the liquid is
            absorbed, 15 to 18 minutes. Reduce heat to medium-low, add reserved
            shrimp and mussels, tucking them down into the rice, and cook again
            without stirring, until mussels have opened and rice is just tender,
            5 to 7 minutes more. (Discard any mussels that don&apos;t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then
            serve.
          </Typography>
        </CardContent> */}
      </Collapse>
    </Card>
  );
}
