import { api } from "./slices/api";
import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import apiInit from "./slices/apiInit";
import logoutSlice from "./slices/logoutSlice";
import apartmentSlice from "./slices/apartmentSlice";
import loadingSlice from "./slices/loadingSlice";
import meterSlice from "./slices/meterSlice";
import snackbarSlice from "./slices/snackbarSlice";
import authSlice from "./slices/authSlice";
import settingsSlice from "./slices/settingsSlice";
import errorSlice from "./slices/errorSlice";
export const store = apiInit(api);
console.log("zaebalsya", store);
const rootReducer = combineReducers({
  settingsSlice,
  logoutSlice,
  errorSlice,
  authSlice,
  apartmentSlice,
  loadingSlice,
  meterSlice,
  snackbarSlice,
  [api.reducerPath]: api.reducer,
});
export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    /* .concat(dataApi.middleware) */
    /*  .concat(digitalTwinInstanceRtk.middleware) */
    /*   .concat([socketMiddleware]), */
  });
};
