import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Bar,
  Brush,
} from "recharts";

import theme from "../theme";

const WaterMeterConsumptionsGraph = () => {
  const { t } = useTranslation();
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);
  const data = consumptions?.map((item) => ({
    name: item?.date,
    value: item?.value /* .toFixed(3) */,
    type: item?.type,
    consumptionType: item?.consumption_type,
  }));

  useEffect(() => {
    console.log("datagraph", consumptions);
  }, [consumptions]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        style={{ margin: 4, marginTop: 18 }}
        height={400}
        data={data}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <Tooltip
          labelFormatter={(value) => {
            return t("dt_n", { val: new Date(value) });
          }}
          formatter={(value) => t("number", { val: value })}
        />
        <Line
          type="monotone"
          dataKey="value"
          stroke={theme.palette.network.main}
          isAnimationActive={false}
        />
        <CartesianGrid stroke={theme.palette.device.main} />
        <XAxis
          dataKey="name"
          label={{ value: t("date"), position: "insideBottom", offset: 0 }}
          angle={45}
          height={90}
          tickMargin={30}
          interval="preserveStartEnd"
          tickFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
          stroke={theme.palette.group_and_district.main}
          tick={{ fontSize: 10 }}
        />
        <YAxis
          label={{
            value: t("volume"),
            angle: -90,
            position: "insideLeft",
            style: { fontSize: "10px" },
          }}
          tick={{ fontSize: 9 }}
          stroke={theme.palette.group_and_district.main}
          tickFormatter={(value) => t("number", { val: value })}
        />
        <Tooltip formatter={(value) => t("number", { val: value })} /> //
      </LineChart>
    </ResponsiveContainer>
  );
};
export default WaterMeterConsumptionsGraph;
