import Table from "./Table";
import MetersTableTab from "./MetersTableTab";
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import { useParams } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch, useSelector } from "react-redux";
import ConsumptionGrafic from "./ConsumptionGrafic";
import ApartmentConsumptionTable from "./ApartmentConsumptionTable";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { store } from "../redux/store";
import { actions } from "../redux/slices/meterSlice";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import MeterCard from "./MeterCard";
import { actions as authActions } from "../redux/slices/authSlice";
import IwdDataGrid from "./utils/IwdDataGrid";
import {
  Tabs,
  Tab,
  Typography,
  TextField,
  Tooltip,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import theme from "./theme";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ApartamentShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getDevices] = store.useLazyGetApartmentDevicesQuery();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const devices = useSelector((state) => state?.apartmentSlice?.devices);
  const preferences = useSelector((state) => state?.authSlice?.preferences);
  const [updatePreferences, { isSuccess: updateSuccess, status: updPending }] =
    store.useUpdatePreferenceMutation();
  const [getUserPreferences, { status: getPrefPending }] =
    store.useLazyGetMeQuery();
  const initialMeterMode = useSelector(
    (state) => state?.authSlice?.initialMeterMode
  );
  const meterMode = useSelector((state) => state?.authSlice?.meterMode);
  const [tableMode, setTableMode] = useState(false);
  const [value, setValue] = useState(0);
  const apartment = useSelector(
    (state) => state?.apartmentSlice?.apartmentName
  );
  const { id } = useParams();
  const [meterType, setMeterType] = useState(["AFS"]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  useEffect(() => {
    if (id) {
      getDevices({
        id: id,
        params: {
          page: page,
          per_page: perPage,
        },
      });
    }
  }, [id]);
  useEffect(() => {
    dispatch(actions.meterCleanUp());
    dispatch(authActions.setCode(null));
  }, []);

  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
  };

  const hcaDevices = devices?.filter((item) => item?.fields?.type == "08");
  useEffect(() => {
    console.log("devix", hcaDevices);
  }, [hcaDevices]);
  const hcaColumns = [
    {
      field: "id",
      headerName: t("name"),
      hide: true,
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "serial_number",
      headerName: t("name"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "meter_type",
      headerName: t("meter_type"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "date",
      headerName: t("date"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "last_reading",
      headerName: t("last_reading"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    /*  {
      field: "unit",
      headerName: t("unit"),

      sortable: false,
      editable: false,
      flex: 2,
    }, */
    {
      field: "room",
      headerName: t("room"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,

      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() => {
                dispatch(actions.setMeterType(params?.value?.type));
                dispatch(actions.setMeterShow(params?.value?.meter));
                localStorage.setItem(
                  "meterShow",
                  JSON.stringify(params?.value?.meter)
                );
                localStorage.setItem("meterType", params?.value?.type);
                navigate(
                  `/meters/${params?.value?.appId}/${params?.value?.meterId}`
                );
              }}
            >
              <VisibilityIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            {/*  <IconButton onClick={() => onDeleteClick(params?.value.id)}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton> */}
          </Box>
        );
      },
    },
  ];
  const hcaRows = hcaDevices?.map((item, i) => ({
    id: i,
    serial_number: item?.serial_number,
    meter_type: item?.fields?.meter_type,
    date: item?.fields?.last_reading?.datetime_utc,
    last_reading: item?.fields?.last_reading?.value
      ? t("number", {
          val: item?.fields?.last_reading?.value,
        })
      : "NA",
    room: item?.fields?.room,
    unit: item?.fields?.last_reading?.unit,
    actions: {
      appId: id,
      meterId: item?.id,
      type: item?.fields?.type,
      meter: item,
    },
  }));

  const waterDevices = devices?.filter((item) => item?.fields?.type == "07");
  const waterColumns = [
    {
      field: "id",
      headerName: t("name"),
      hide: true,
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "serial_number",
      headerName: t("name"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "meter_type",
      headerName: t("meter_type"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "date",
      headerName: t("date"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "last_reading",
      headerName: t("last_reading"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "unit",
      headerName: t("unit"),

      sortable: false,
      editable: false,
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,

      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() => {
                dispatch(actions.setMeterType(params?.value?.type));
                dispatch(actions.setMeterShow(params?.value?.meter));
                localStorage.setItem(
                  "meterShow",
                  JSON.stringify(params?.value?.meter)
                );
                localStorage.setItem("meterType", params?.value?.type);
                navigate(
                  `/meters/${params?.value?.appId}/${params?.value?.meterId}`
                );
              }}
            >
              <VisibilityIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            {/*  <IconButton onClick={() => onDeleteClick(params?.value.id)}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton> */}
          </Box>
        );
      },
    },
  ];
  const waterRows = waterDevices?.map((item, i) => ({
    id: i,
    serial_number: item?.serial_number,
    meter_type: item?.fields?.meter_type,
    date: item?.fields?.last_reading?.datetime_utc,
    last_reading: item?.fields?.last_reading?.value
      ? t("number", {
          val: item?.fields?.last_reading?.value,
        })
      : "NA",

    unit: item?.fields?.last_reading?.unit,
    actions: {
      appId: id,
      meterId: item?.id,
      type: item?.fields?.type,
      meter: item,
    },
  }));
  const heatDevices = devices?.filter((item) => item?.fields?.type == "13");
  const heatColumns = [
    {
      field: "id",
      headerName: t("name"),
      hide: true,
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "serial_number",
      headerName: t("name"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "meter_type",
      headerName: t("meter_type"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "date",
      headerName: t("date"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "calories",
      headerName: t("calories"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "frigories",
      headerName: t("frigories"),

      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "unit",
      headerName: t("unit"),

      sortable: false,
      editable: false,
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,

      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() => {
                dispatch(actions.setMeterType(params?.value?.type));
                dispatch(actions.setMeterShow(params?.value?.meter));
                localStorage.setItem(
                  "meterShow",
                  JSON.stringify(params?.value?.meter)
                );
                localStorage.setItem("meterType", params?.value?.type);
                navigate(
                  `/meters/${params?.value?.appId}/${params?.value?.meterId}`
                );
              }}
            >
              <VisibilityIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            {/*  <IconButton onClick={() => onDeleteClick(params?.value.id)}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton> */}
          </Box>
        );
      },
    },
  ];
  const heatRows = heatDevices?.map((item, i) => ({
    id: i,
    serial_number: item?.serial_number,
    meter_type: item?.fields?.meter_type,
    date: item?.fields?.last_reading?.datetime_utc,
    calories: item?.fields?.heat_last_reading?.value
      ? t("number", {
          val: item?.fields?.heat_last_reading?.value / 1000,
        })
      : "NA",
    frigories: item?.fields?.cold_last_reading?.value
      ? t("number", {
          val: item?.fields?.cold_last_reading?.value / 1000,
        })
      : "NA",

    unit: "kWh",
    actions: {
      appId: id,
      meterId: item?.id,
      type: item?.fields?.type,
      meter: item,
    },
  }));
  const colunsSelector = () => {
    switch (value) {
      case 0:
        return waterColumns;
      case 1:
        return hcaColumns;
      case 2:
        return heatColumns;
    }
  };
  const rowsSelector = () => {
    switch (value) {
      case 0:
        return waterRows;
      case 1:
        return hcaRows;
      case 2:
        return heatRows;
    }
  };
  const coutnSelector = () => {
    switch (value) {
      case 0:
        return waterRows?.length;
      case 1:
        return hcaRows?.length;
      case 2:
        return heatRows?.length;
    }
  };

  const deboucedTable = useDebouncedCallback(() => {
    if (meterMode !== initialMeterMode) {
      const attributes = {
        ...preferences,
        apartment_mode: "table",
      };
      updatePreferences(attributes);
    }
  }, [1000]);
  const deboucedCard = useDebouncedCallback(() => {
    if (meterMode !== initialMeterMode) {
      const attributes = {
        ...preferences,
        apartment_mode: "card",
      };
      updatePreferences(attributes);
    }
  }, [1000]);
  const setTable = () => {
    dispatch(authActions.setMeterMode("table"));
    deboucedTable();
  };
  const setCard = () => {
    dispatch(authActions.setMeterMode("card"));
    deboucedCard();
  };
  return (
    <Box sx={{ ml: 2, height: 835 }}>
      <Typography
        sx={{ mt: 3 }}
        variant="h3"
        color={theme?.palette?.primary?.main}
      >
        {`Appartamento  ${apartment ?? ""}`}{" "}
        {meterMode == "table" && (
          <IconButton
            disabled={updPending == "pending" || getPrefPending == "pending"}
            onClick={setCard}
          >
            <Tooltip title={t("show_grid")}>
              <ViewModuleIcon />
            </Tooltip>
          </IconButton>
        )}
        {meterMode == "card" && (
          <IconButton
            disabled={updPending == "pending" || getPrefPending == "pending"}
            onClick={setTable}
          >
            <Tooltip title={t("show_list")}>
              <TableRowsIcon />
            </Tooltip>
          </IconButton>
        )}
      </Typography>
      {meterMode == "table" && (
        <Box sx={{ mt: 2 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab disabled={waterDevices?.length == 0} label={t("AFS-ACS")} />
            <Tab disabled={hcaDevices?.length == 0} label={t("distributor")} />
            <Tab disabled={heatDevices?.length == 0} label={t("color_meter")} />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            <IwdDataGrid
              columns={colunsSelector()}
              perPage={perPage}
              setPage={setPage}
              rows={rowsSelector()}
              page={page}
              count={coutnSelector()}
              paginationMode={"client"}
              sort={false}
              height={600}
            />
          </Box>
        </Box>
      )}
      {meterMode == "card" && (
        <Box
          display={"flex"}
          sx={{ mt: 2 }}
          flexWrap={"wrap"}
          justifyContent={isMobile ? "center" : "start"}
        >
          {devices?.map((item) => (
            <MeterCard
              type={item?.fields?.type}
              date={item?.fields?.last_reading?.datetime_utc}
              value={item?.fields?.last_reading?.value}
              unit={item?.fields?.last_reading?.unit}
              coldValue={item?.fields?.cold_last_reading?.value}
              heatValue={item?.fields?.heat_last_reading?.value}
              colorUnit={item?.fields?.cold_last_reading?.unit}
              colorDate={item?.fields?.cold_last_reading?.datetime_utc}
              serial={item?.fields?.serial ?? item?.serial_number}
              room={item?.fields?.room ?? item?.fields?.room}
              appId={id}
              meterId={item?.id}
              meter={item}
              maxWidth="21rem"
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ApartamentShow;
