import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  consumptions: null,
  meterShow: JSON.parse(localStorage.getItem("meterShow")),
  consumCount: null,
  readings: null,
  readingCount: null,
  meterType: localStorage.getItem("meterType"),
  allarms: null,
};

const MeterSlice = createSlice({
  name: "MeterSlice",
  initialState,
  reducers: {
    setMeterType(state, action) {
      state.meterType = action.payload;
    },
    setMeterShow(state, action) {
      state.meterShow = action.payload;
    },
    meterCleanUp(state) {
      state.consumptions = null;
      state.readings = null;
      state.allarms = null;
      state.meterType = null;
      state.meterShow = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.meterEndpoints.getReadings.matchFulfilled,
      (state, action) => {
        state.readings = action.payload.data;
      }
    );
    builder.addMatcher(
      store.meterEndpoints.getConsumptions.matchFulfilled,
      (state, action) => {
        state.consumptions = action.payload.data;
      }
    );
    builder.addMatcher(
      store.meterEndpoints.getAllarms.matchFulfilled,
      (state, action) => {
        const updatedData = action.payload.data.map((item) => {
          const resultItem = { ...item }; // Создаем копию объекта
          const group = [];
          if (Array.isArray(item.date_times) && Array.isArray(item.errors)) {
            item.date_times.forEach((date_time, index) => {
              const error = item.errors[index] || "No Error";
              group.push([error, date_time]);
            });
          }
          resultItem.date_times_errors = group; // Добавляем новую структуру с массивами date_times и errors
          return resultItem;
        });
        state.allarms = updatedData; // Сохраняем обновленные данные в состоянии
      }
    );
  },
});

//TODO: try to do it better

export const actions = MeterSlice.actions;
export default MeterSlice.reducer;
