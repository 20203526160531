import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Theme from "@rjsf/mui";
import ColorPickerWidget from "./ColorPickerWidget";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const CreateConfig = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const schema = {
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
      },
      url: {
        $id: "1",
        type: "string",
        title: t("url"),
      },
      colors: {
        type: "object",
        properties: {
          primary: {
            type: "string",
          },
          secondary: {
            type: "string",
          },
        },
      },
      logo_id: {
        type: "string",
        title: t("logo"),
      },
      favicon_id: {
        type: "string",
        title: t("fav icon"),
      },
    },
    required: ["name"],
  };
  const fields = {
    colorpicker: ColorPickerWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    colors: {
      "ui:field": "colorpicker",
    },
  };
  return (
    <>
      <ColorPickerWidget />
      <Typography variant="h1" sx={{ mt: 2, mb: 2 }}>
        {t("create_config")}
      </Typography>
      <Form
        schema={schema}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        /*    onSubmit={onSubmitClick} */
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        fields={fields}
        /*   customValidate={customValidate} */
        noHtml5Validate
      />
    </>
  );
};

export default CreateConfig;
