import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../redux/slices/authSlice";

const Logged = () => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState();
  const preference = useSelector(
    (state) => state.userPreferenceSlice?.preference
  );
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);

  const currentToken = useSelector((state) => state?.authSlice?.currentToken);
  const { token } = useParams();
  const bearer = localStorage.getItem("user_token");
  const navigate = useNavigate();
  /*  const from = actions.getFrom() || "/"; "/metrics"; */
  /*   useEffect(() => {
    setFrom(JSON.parse(localStorage.getItem("from")));
  }, []); */

  useEffect(() => {
    if (currentToken == null) {
      dispatch(actions.signin(token));
    }
  }, [currentToken]);
  /*   useEffect(() => {
    if (currentToken) {
      getMe();
    }
  }, [currentToken]);
 */
  useEffect(() => {
    if (bearer) {
      navigate("/", { redirect: true });
    }
  }, [bearer]);
  return <Loader />;
};

export default Logged;
