import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { store } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import ApartamentIndex from "./ApartamentIndex";
import { useDebouncedCallback } from "use-debounce";
import { setDevices } from "../redux/slices/apartmentSlice";
import { actions } from "../redux/slices/authSlice";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import theme from "./theme";

const MainPage = () => {
  const { t } = useTranslation();
  const [updatePreferences, { isSuccess: updateSuccess, status: updPending }] =
    store.useUpdatePreferenceMutation();
  const [getUserPreferences, { status: getPrefPending }] =
    store.useLazyGetMeQuery();
  const apartmentMode = useSelector((state) => state?.authSlice?.apartmentMode);
  const initialApartmentMode = useSelector(
    (state) => state?.authSlice?.initialApartmentMode
  );
  const preferences = useSelector((state) => state?.authSlice?.preferences);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  useEffect(() => {
    dispatch(setDevices(null));
  });
  useEffect(() => {
    if (uuid) {
      dispatch(actions.setCode(uuid));
    }
  }, [uuid]);

  useEffect(() => {
    if (updateSuccess) {
      getUserPreferences();
    }
  }, [updateSuccess]);

  const deboucedTable = useDebouncedCallback(() => {
    if (apartmentMode !== initialApartmentMode) {
      const attributes = {
        ...preferences,
        apartment_mode: "table",
      };
      updatePreferences(attributes);
    }
  }, [1000]);
  const deboucedCard = useDebouncedCallback(() => {
    if (apartmentMode !== initialApartmentMode) {
      const attributes = {
        ...preferences,
        apartment_mode: "card",
      };
      updatePreferences(attributes);
    }
  }, [1000]);
  const setTable = () => {
    dispatch(actions.setApartmentMode("table"));
    deboucedTable();
  };
  const setCard = () => {
    dispatch(actions.setApartmentMode("card"));
    deboucedCard();
  };

  return (
    <>
      <Typography
        variant="h3"
        textAlign={"center"}
        sx={{
          color: theme.palette.primary.main,
          p: 2,
        }}
      >
        Select your apartment
        <IconButton onClick={() => navigate("/apartments/add")}>
          <Tooltip title={t("assoc_app")}>
            <AddIcon />
          </Tooltip>
        </IconButton>
        {apartmentMode == "table" && (
          <IconButton
            disabled={updPending == "pending" || getPrefPending == "pending"}
            onClick={setCard}
          >
            <Tooltip title={t("show_grid")}>
              <ViewModuleIcon />
            </Tooltip>
          </IconButton>
        )}
        {apartmentMode == "card" && (
          <IconButton
            disabled={updPending == "pending" || getPrefPending == "pending"}
            onClick={setTable}
          >
            <Tooltip title={t("show_list")}>
              <TableRowsIcon />
            </Tooltip>
          </IconButton>
        )}
      </Typography>

      <Divider />
      <ApartamentIndex />
    </>
  );
};
export default MainPage;
