import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  modal: false,
  meterMode: "card",
  apartmentMode: "card",
};

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {
    setMeterMode(state, action) {
      state.meterMode = action.payload;
    },
    setApartmentMode(state, action) {
      state.apartmentMode = action.payload;
    },
    setModal(state, action) {
      state.modal = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

//TODO: try to do it better

export const settingActions = settingsSlice.actions;
export default settingsSlice.reducer;
