import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  message: null,
  open: false,
  severity: "success",
};

const snackbarSlice = createSlice({
  name: "snackbarSlice",
  initialState,
  reducers: {
    setMessage(state, action) {
      state.message = action.payload;
    },
    setSeverity(state, action) {
      state.severity = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setMessage, setOpen, setSeverity } = snackbarSlice.actions;
export default snackbarSlice.reducer;
