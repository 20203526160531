import { useState } from "react";
import { StyledDataGrid } from "../utils/StyledDataGrid";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import theme from "../theme";
const ColorTable = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const data = useSelector((state) => state?.meterSlice?.readings);
  useEffect(() => {
    console.log("data", data);
  }, [data]);
  const { t } = useTranslation();
  const columns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("date_val", { val: new Date(params.row.messageDate) })}</>;
      },
    },
    {
      field: "calorie",
      headerName: t("Calorie"),

      editable: false,
      flex: 1,
    },
    {
      field: "frigorie",
      headerName: t("Frigorie"),
      editable: false,

      editable: false,
      flex: 1,
    },
    {
      field: "unit",
      headerName: t("unit"),
      editable: false,

      editable: false,
      flex: 1,
    },
  ];
  const rows = data?.map((item, i) => ({
    id: i,
    messageDate: item?.values?.read_time ?? "",
    calorie:
      item?.values?.values?.value?.heat_last_reading?.value &&
      item?.values?.values?.value?.heat_last_reading?.value !== null
        ? `${`${t("number", {
            val: item?.values?.values?.value?.heat_last_reading?.value / 1000,
          })}`} `
        : "--",
    frigorie:
      item?.values?.values?.value?.cold_last_reading?.value &&
      item?.values?.values?.value?.cold_last_reading?.value !== null
        ? `${`${t("number", {
            val: item?.values?.values?.value?.cold_last_reading?.value / 1000,
          })}`} `
        : "--",

    unit: "kWh",
  }));
  return (
    <Box sx={{ flexGrow: 1, width: "98%", mt: 2, /* mr: 2, */ height: "80%" }}>
      <Typography
        sx={{ mb: 1 }}
        color={theme.palette.primary.main}
        variant="h6"
      >
        {t("readings")}
      </Typography>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        rowCount={data?.length}
        paginationMode="client"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default ColorTable;
