import {
  Tabs,
  Tab,
  Typography,
  TextField,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import theme from "../theme";

const RegistredPage = () => {
  return (
    <Box>
      <Typography
        textAlign={"center"}
        variant="h2"
        sx={{ color: theme.palette.primary.main }}
      >
        La registrazione è stata completata con successo. Ti invieremo un'email
        per finalizzare il processo
      </Typography>
    </Box>
  );
};
export default RegistredPage;
