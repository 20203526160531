import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";
import Header from "./Header";
import Divider from "@mui/material/Divider";

import ApartamentIndex from "./ApartamentIndex";
import Typography from "@mui/material/Typography";
import theme from "./theme";

const CardWrapper = (props) => {
  return (
    <>
      <Card
        sx={{
          minHeight: 900,
          height: "fit-content",
          background: "#f5fcfb",
          m: 1,
        }}
      >
        <CardContent sx={{ maxHeight: 900, overflowY: "auto", mt: 2 }}>
          {props.children}
        </CardContent>
      </Card>
    </>
  );
};
export default CardWrapper;
