import { prepareUserBody } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";
export const authApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      signout: build.mutation({
        query: () => ({
          url: "v1/logout",
          method: "DELETE",
        }),
      }),
      addUser: build.mutation({
        query: (attributes) => ({
          url: "/v1/users",
          method: "POST",
          body: JSON.stringify(prepareUserBody(attributes)),
        }),
      }),
      deleteAccount: build.mutation({
        query: () => ({
          url: "v1/me",
          method: "DELETE",
        }),
      }),
      getMe: build.query({
        query: () => {
          return "v1/me";
        },
      }),
      updatePreference: build.mutation({
        query: (attributes) => ({
          url: `v1/me/user_preferences`,
          method: "PATCH",
          body: JSON.stringify(
            prepareBodyWithoutId(attributes, "user_preferences")
          ),
        }),
      }),
    }),
  });
