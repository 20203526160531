import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import GraficCard from "./GraphicCard";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip as PieTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Bar,
  Brush,
} from "recharts";

import theme from "../theme";
const HeatConsumptionsGraph = () => {
  const { t } = useTranslation();
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);

  const data = consumptions?.reduce((acc, curr) => {
    const currentDate = new Date(curr.date);

    const formattedDate = currentDate.toISOString();

    if (
      currentDate.getTime() === currentDate.getTime() &&
      curr.consumption_type === "heat"
    ) {
      acc[formattedDate] = {
        ...acc[formattedDate],
        calories: curr.value,
        type: curr.type,
      };
    } else if (
      currentDate.getTime() === currentDate.getTime() &&
      curr.consumption_type === "cold"
    ) {
      acc[formattedDate] = {
        ...acc[formattedDate],
        frigories: curr.value,
        type: curr.type,
      };
    }
    return acc;
  }, {});
  const resultArray =
    data &&
    Object?.keys(data)?.map((date) => ({
      date,
      calories: data[date].calories / 1000 || 0,
      frigories: data[date].frigories / 1000 || 0,
      type: data[date].type,
    }));
  useEffect(() => {
    console.log("rsltarr", resultArray);
  }, [resultArray]);
  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart height={500} data={resultArray} style={{ marginTop: 18 }}>
        <PieTooltip
          content={({ active, payload, label }) => {
            const clen = payload;

            if (payload?.[0]?.payload?.type == 1) {
              return (
                <GraficCard
                  coldValue={`Frigories: ${t("number", {
                    val: t("number", { val: payload?.[0]?.payload?.frigories }),
                    maximumFractionDigits: 3,
                  })}`}
                  heatValue={`Calories: ${t("number", {
                    val: t("number", { val: payload?.[0]?.payload?.calories }),
                    maximumFractionDigits: 3,
                  })}`}
                  icon={
                    <CalculateIcon fontSize="large" sx={{ fill: "green" }} />
                  }
                  date={`Date: ${t("date_val", {
                    val: new Date(payload?.[0]?.payload?.date),
                  })}`}
                />
              );
            } else {
              return (
                <GraficCard
                  coldValue={`Frigories: ${t("number", {
                    val: t("number", { val: payload?.[0]?.payload?.frigories }),
                    maximumFractionDigits: 3,
                  })}`}
                  heatValue={`Calories: ${t("number", {
                    val: t("number", { val: payload?.[0]?.payload?.calories }),
                    maximumFractionDigits: 3,
                  })}`}
                  icon={
                    <OnlinePredictionIcon
                      fontSize="large"
                      sx={{ fill: "#8884d8" }}
                    />
                  }
                  date={`Date: ${t("date_val", {
                    val: new Date(payload?.[0]?.payload?.date),
                  })}`}
                />
              );
            }
          }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          label={{ value: t("date"), position: "insideBottom", offset: 0 }}
          dataKey="date"
          angle={70}
          height={90}
          tickMargin={20}
          interval="preserveStartEnd"
          style={{ fontSize: "10px" }}
          stroke={theme.palette.group_and_district.main}
          tickFormatter={(value) => {
            return t("date_val", { val: new Date(value) });
          }}
        />

        <YAxis
          type="number"
          label={{
            value: t("kWh"),
            angle: -45,
            position: "insideLeft",
          }}
          style={{ fontSize: "10px" }}
          stroke={theme.palette.group_and_district.main}
        />
        <Line
          type="monotone"
          dataKey="calories"
          stroke="red"
          isAnimationActive={false}
        />

        <Line
          type="monotone"
          dataKey="frigories"
          isAnimationActive={false}
          stroke="blue"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HeatConsumptionsGraph;
