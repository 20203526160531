import { StyledDataGrid } from "../utils/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useDispatch, useSelector } from "react-redux";

import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import theme from "../theme";

const HeatConsumptionsTable = () => {
  const { t } = useTranslation();
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
      flex: 1,

      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "calories",
      headerName: t("calories_consum"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "frigories",
      headerName: t("frigories_consum"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "unit",
      headerName: t("unit"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const heatConsumptions =
    consumptions &&
    consumptions?.reduce((acc, curr) => {
      const existingEntryIndex = acc.findIndex(
        (entry) => entry.date === curr.date
      );
      if (existingEntryIndex !== -1) {
        if (curr.consumption_type === "heat") {
          acc[existingEntryIndex].calories = curr.value;
        } else if (curr.consumption_type === "cold") {
          acc[existingEntryIndex].frigories = curr.value;
        }
      } else {
        const newObj = {
          ...curr,
          calories: curr.consumption_type === "heat" ? curr.value : undefined,
          frigories: curr.consumption_type === "cold" ? curr.value : undefined,
        };
        acc.push(newObj);
      }
      return acc;
    }, []);

  useEffect(() => {
    console.log("hcnmpt", heatConsumptions);
  }, [heatConsumptions]);
  const rows = heatConsumptions?.map((item, i) => ({
    id: i,
    date: t("date_val", { val: new Date(item?.date) }),

    type: item.type,
    calories:
      item?.calories !== null
        ? t("number", {
            val: item.calories / 1000,
          })
        : "--",
    frigories:
      item?.frigories !== null
        ? t("number", {
            val: item.frigories / 1000,
          })
        : "--",
    unit: "kWh",
  }));
  return (
    <Box sx={{ flexGrow: 1, width: "98%", mt: 2, height: "80%" }}>
      <Typography
        sx={{ mb: 1 }}
        color={theme.palette.primary.main}
        variant="h6"
      >
        {t("consumptions")}
      </Typography>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        /*      rowCount={consumptions?.length} */

        disableSelectionOnClick
        // loading={isLoadingTable}
        paginationMode="client"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default HeatConsumptionsTable;
