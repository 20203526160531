import { StyledDataGrid } from "../utils/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useDispatch, useSelector } from "react-redux";

import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import theme from "../theme";

const WaterConsumptionsTable = () => {
  const data = useSelector((state) => state?.meterSlice?.consumptions);
  const { t } = useTranslation();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "left",
    },

    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
    },

    {
      field: "type",
      headerName: t("type"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value == 1) {
          return (
            <Tooltip title={t("simulated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t("calculated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        }
      },
    },
    {
      field: "value",
      headerName: t("value"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
    {
      field: "unit",
      headerName: t("unit"),
      type: "number",
      headerAlign: "left",
      editable: false,
      flex: 2,
    },
  ];
  const rows = data?.map((item, i) => ({
    id: i,
    date: t("date_val", { val: new Date(item?.date) }),
    type: item?.type,
    value: item?.value,
    unit: item?.meta?.unit,
  }));

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  return (
    <Box sx={{ flexGrow: 1, /*  width: "95%", */ mt: 2, height: "80%" }}>
      <Typography
        sx={{ mb: 1 }}
        color={theme.palette.primary.main}
        variant="h6"
      >
        {t("consumptions")}
      </Typography>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        /*      rowCount={consumptions?.length} */

        disableSelectionOnClick
        // loading={isLoadingTable}
        paginationMode="client"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default WaterConsumptionsTable;
