import {
  PieChart,
  Tooltip as PieTooltip,
  Cell,
  LineChart,
  BarChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  Bar,
  Brush,
  ReferenceArea,
  ReferenceDot,
  ResponsiveContainer,
} from "recharts";
import GraficCard from "./GraphicCard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import theme from "../theme";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Box, Typography } from "@mui/material";
const HcaConsumtionsGraph = () => {
  const [aloccatorValue, setAllocatorValue] = useState(true);
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);
  const { t } = useTranslation();
  const data = consumptions?.map((item) => ({
    name: t("date_val", { val: new Date(item?.date) }),
    value: item?.value /* .toFixed(3) */,
    type: item?.type,
    consumptionType: item?.consumption_type,
  }));
  const allocatorValueToggle = () => [setAllocatorValue(!aloccatorValue)];
  const convertedGraphData = consumptions?.filter(
    (item) => item?.consumption_type == "converted_value"
  );
  const unconvertedGraphData = consumptions?.filter(
    (item) => item?.consumption_type == "unconverted_value"
  );

  useEffect(() => {
    console.log("convertedGraphData", convertedGraphData);
  }, [convertedGraphData]);
  return (
    <Box width={"150rem"}>
      <ResponsiveContainer width="100%" height={520}>
        <LineChart
          height={530}
          margin={{
            left: 0,
            top: 10,
            right: 30,
          }}
          data={aloccatorValue ? convertedGraphData : unconvertedGraphData}
        >
          <PieTooltip
            content={({ active, payload, label }) => {
              const clen = payload;
              console.log("huhuhui", clen);
              if (payload?.[0]?.payload?.type == 1) {
                return (
                  <GraficCard
                    value={`${
                      aloccatorValue
                        ? t("converted_value")
                        : t("unconverted_value")
                    }: ${t("number", {
                      val: payload?.[0]?.payload?.value,
                      maximumFractionDigits: 3,
                    })}`}
                    icon={
                      <CalculateIcon fontSize="large" sx={{ fill: "green" }} />
                    }
                    date={`Date:  ${t("date_val", {
                      val: new Date(payload?.[0]?.payload?.date),
                    })}`}
                  />
                );
              } else {
                return (
                  <GraficCard
                    value={`${
                      aloccatorValue
                        ? t("converted_value")
                        : t("unconverted_value")
                    }: ${t("number", {
                      val: payload?.[0]?.payload?.value,
                      maximumFractionDigits: 3,
                    })}`}
                    icon={
                      <OnlinePredictionIcon
                        fontSize="large"
                        sx={{ fill: "#8884d8" }}
                      />
                    }
                    date={`Date:  ${t("date_val", {
                      val: new Date(payload?.[0]?.payload?.date),
                    })}`}
                  />
                );
              }
            }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke={theme.palette.network.main}
            isAnimationActive={false}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            label={{ value: t("date"), position: "insideBottom", offset: 5 }}
            dataKey="date"
            angle={45}
            interval="preserveStartEnd"
            height={90}
            stroke={theme.palette.group_and_district.main}
            tickMargin={30}
            tickFormatter={(value) => {
              return t("date_val", { val: new Date(value) });
            }}
          />
          <YAxis
            type="number"
            label={{
              /*      value: t("J"), */

              position: "insideLeft",
            }}
            style={{ fontSize: "10px" }}
            stroke={theme.palette.group_and_district.main}
          />

          <Bar dataKey="value" fill="#8884d8" minPointSize={5} />
        </LineChart>
      </ResponsiveContainer>
      <Box display={"flex"} justifyContent={"flex-end"} sx={{ mt: 2 }}>
        <FormGroup>
          <FormControlLabel
            value={aloccatorValue}
            onClick={allocatorValueToggle}
            control={<Switch defaultChecked />}
            label={
              aloccatorValue ? t("converted_value") : t("unconverted_value")
            }
          />
        </FormGroup>
      </Box>
    </Box>
  );
};
export default HcaConsumtionsGraph;
