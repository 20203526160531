import React, { Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";
import { ThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";
import MainPage from "./components/MainPage";
import Header from "./components/Header";
import ApartamentShow from "./components/ApartmentShow";
import CardWrapper from "./components/CardWrapper";
import MeterShow from "./components/MeterShow";
import ErrorsHandler from "./components/utils/ErrorsHandler";
import AddApartment from "./components/AddApartment";
import RequireAuth from "./components/auth/RequireAuth";
import theme from "./components/theme";
import CustomSnackbar from "./utils/Snackbars";
import LoginPage from "./components/auth/Login";
import AddUser from "./components/AddUser";
import GlobalLoading from "./components/utils/GlobalLoading";
import Logged from "./components/auth/Logged";
import RegistredPage from "./components/utils/RegistredPage";
import ErrorsShow from "./components/utils/ErrorsShow";
import UserProfile from "./components/UserProfile";
import { setupStore } from "./redux/store";
import CreateConfig from "./components/config/CreateConfig";
const store = setupStore({});
function Ade() {
  // 👇️ redirect to external URL
  window.location.replace("http://192.168.1.65:9295/");

  return null;
}

/* function ProtectedPage() {
  return <h3>Protected</h3>;
}
library.add(fab, faCheckSquare, faCoffee); */

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalLoading />

        <CustomSnackbar />
        <BrowserRouter>
          <Header />
          <ErrorsShow />
          {/*   <ErrorsHandler /> */}
          <CardWrapper>
            <Routes>
              <Route
                path="/:uuid?"
                element={
                  <RequireAuth>
                    <MainPage />
                  </RequireAuth>
                }
              />
              <Route
                path="configurations/create"
                element={
                  <RequireAuth>
                    <CreateConfig />
                  </RequireAuth>
                }
              />
              <Route path="/logged/:token" element={<Logged />} />
              <Route
                path="meters/:appId/:meterId"
                element={
                  <RequireAuth>
                    <MeterShow />
                  </RequireAuth>
                }
              />
              <Route
                path="me"
                element={
                  <RequireAuth>
                    <UserProfile />
                  </RequireAuth>
                }
              />
              <Route
                path="apartment/:id"
                element={
                  <RequireAuth>
                    <ApartamentShow />
                  </RequireAuth>
                }
              />
              <Route
                path="apartments/add"
                element={
                  <RequireAuth>
                    <AddApartment />
                  </RequireAuth>
                }
              />
              <Route path="registration" element={<AddUser />} />
              <Route path="registred" element={<RegistredPage />} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
          </CardWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);
reportWebVitals();
