import { useState } from "react";
import { StyledDataGrid } from "../utils/StyledDataGrid";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";
import theme from "../theme";
const HcaTable = () => {
  const data = useSelector((state) => state?.meterSlice?.readings);
  const { t } = useTranslation();
  const columns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,

      editable: false,
      flex: 2,
      renderCell: (params) => {
        return <>{t("dt_n", { val: new Date(params.row.messageDate) })}</>;
      },
    },
    {
      field: "rawValue",
      headerName: t("display_value"),

      editable: false,
      flex: 1,
    },

    {
      field: "k",
      headerName: t("k"),
      editable: false,

      editable: false,
      flex: 1,
    },

    {
      field: "value",
      headerName: t("value"),
      editable: false,

      editable: false,
      flex: 1,
    },
  ];
  const rows = data?.map((item, i) => ({
    id: i,
    messageDate: item?.values?.read_time ?? "--",
    value:
      item?.values?.values?.value?.last_reading?.value !== null &&
      item?.values?.values?.value?.last_reading?.value
        ? `${t("number", {
            val: item?.values?.values?.value?.last_reading?.value,
          })}`
        : "--",
    rawValue:
      item?.values?.values?.value?.last_unconverted_reading !== null
        ? item?.values?.values?.value?.last_unconverted_reading?.value
        : "--",
    /*    unit: item?.unit, */
    k:
      item?.values?.values?.value?.k !== null
        ? t("number", {
            val: item?.values?.values?.value?.k,

            maximumFractionDigits: 3,
          })
        : "--",
  }));
  return (
    <Box sx={{ flexGrow: 1, width: "98%", mt: 2, height: "80%" }}>
      <Typography
        sx={{ mb: 1 }}
        color={theme.palette.primary.main}
        variant="h6"
      >
        {t("readings")}
      </Typography>
      <StyledDataGrid
        onSortModelChange={(model) => console.log("test")}
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        pageSize={10}
        // rowsPerPageOptions={[perPage]}
        // paginationMode="client"
        /*     onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }} */
        /*  page={page - 1} */

        paginationMode="client"
        getRowHeight={() => "auto"}

        // sortingMode="server"
        // onSortModelChange={handleSortModelChange}

        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default HcaTable;
