import { prepareParams } from "../../utils/api_params";
export const meterDataApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getReadings: build.query({
        query: ({ appId, deviceId, params }) => ({
          url: `v1/apartments/${appId}/devices/${deviceId}/messages?${prepareParams(
            params
          )}`,
        }),
      }),
      getConsumptions: build.query({
        query: ({ appId, deviceId, params }) => ({
          url: `v1/apartments/${appId}/devices/${deviceId}/consumptions?${prepareParams(
            params
          )}`,
        }),
      }),
      getAllarms: build.query({
        query: ({ appId, deviceId, params }) => ({
          url: `v1/apartments/${appId}/devices/${deviceId}/alarms?${prepareParams(
            params
          )}`,
        }),
      }),
    }),
  });
