import React from "react";

const LoginPage = () => {
  return (
    <div>
      {window.location.replace(
        `${window.BASE_URL}/api/v1/login?callback=${window.CALLBACK_URL}`
      )}
    </div>
  );
};

export default LoginPage;
