import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import theme from "../theme";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { clearErrors } from "../../redux/slices/errorSlice";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
/* import MenuIcon from "@mui/icons-material/Menu"; */

// Стили для компонентов Material-UI прямо в JSX
const headerStyles = {
  root: {
    flexGrow: 1,
    marginRight: 7,
    marginLeft: 7,
    marginBottom: 2,
    marginTop: 10,
  },
  menuButton: {
    marginRight: 16,
  },
  title: {
    flexGrow: 1,
  },
};

function ErrorsShow() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errors = useSelector((state) => state?.errorSlice?.data);
  const showError = useSelector((state) => state?.errorSlice?.showError);
  const location = useLocation();
  const errorsHandler = () => {
    switch (errors[0]) {
      case "detail: Invalid code":
        return t("invalid_code");
      default:
        return errors[0];
    }
  };
  return (
    <>
      {showError && (
        <Box style={{ ...headerStyles.root, height: 120 }}>
          <AppBar
            sx={{
              background: theme.palette.error.main,
              height: "100%",
              overflow: "auto",
            }}
            position="static"
          >
            {/*  <Toolbar sx={{ justifyContent: "flex-end", height: 3 }}>
          <Tooltip title="Logout">
            <IconButton onClick={() => logout()}>
              
            </IconButton>
          </Tooltip>
        </Toolbar> */}
            <Box display={"flex"} justifyContent={"flex-end"} sx={{ m: 1 }}>
              <IconButton
                onClick={() => dispatch(clearErrors())}
                sx={{ height: 25 }}
              >
                <CloseIcon sx={{ fill: "#ffff", float: "right", m: 1 }} />
              </IconButton>
            </Box>
            <Box display={"flex"} sx={{ p: 1 }}>
              <ErrorIcon sx={{ mt: 1.5, ml: 1 }} />
              <Typography variant="h6" sx={{ m: 1 }}>
                {errorsHandler()}
              </Typography>
            </Box>
          </AppBar>
        </Box>
      )}
    </>
  );
}

export default ErrorsShow;
