import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { store } from "../redux/store";
import LoginIcon from "@mui/icons-material/Login";
import theme from "./theme";
/* import { useAddUserMutation } from "../redux/slices/authApi"; */

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => (
        <Grid key={element.id} item lg={6} xl={6} md={12} xs={12} sm={12}>
          {element.content}
        </Grid>
      ))}
    </Grid>
  );
};

const AddUser = () => {
  const [addUser, { isSuccess }] = store.useAddUserMutation();
  const code = useSelector((state) => state?.authSlice?.registerCode);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lngEnum = [{ const: "it" }, { const: "en" }];
  const [formData, setFormData] = useState();
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      first_name: {
        type: "string",
        title: t("first_name"),
      },
      last_name: {
        type: "string",
        title: t("last_name"),
      },
      user_name: {
        type: "string",
        title: t("user_name"),
      },
      email: {
        type: "string",
        title: t("email"),
      },
      code: {
        type: "string",
        title: t("code"),
        default: code,
      },

      preferred_language: {
        type: "string",
        oneOf: [...lngEnum],
        title: t("preferred_language"),
      },
    },

    required: [
      "first_name",
      "last_name",
      "user_name",
      "email",
      "code",
      "preferred_language",
    ],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
  };
  const onSubmitClick = (obj) => {
    addUser(obj);
  };
  const customValidate = (formData, errors) => {
    debugger;
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    if (!emailRegex.test(formData?.email)) {
      errors.email.addError("Invalid email address");
    }

    return errors;
  };
  useEffect(() => {
    if (isSuccess) {
      navigate(`/registred`);
    }
  }, [isSuccess]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        sx={{ mt: 1, mb: 5 }}
        color={theme.palette.primary.main}
        variant="h3"
      >
        Registrazione
      </Typography>
      <Form
        schema={schema}
        /*    fields={fields} */
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        customValidate={customValidate}
        noHtml5Validate

        /*  templates={{ ErrorListTemplate }} */
      />
      {/* <Button sx={{ mt: 2, float: "right" }} variant="contained">
        Vai al login
      </Button> */}

      <Box sx={{ mt: 2 }} display={"flex"}>
        {/*    <Typography
          onClick={() => navigate(`/login`)}
          sx={{ mt: 0.5, textDecoration: "underline" }}
          variant="h6"
          color={theme.palette.primary.main}
        >
          {`Se sei già registrato, accedi qui `}
        </Typography> */}
        <a
          onClick={() => navigate(`/login`)}
          style={{
            textDecoration: "underline",
            color: theme.palette.primary.main,
            cursor: "pointer",
            marginTop: 10,
          }}
        >{`Se sei già registrato, accedi qui `}</a>
        <IconButton onClick={() => navigate(`/login`)}>
          <LoginIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default AddUser;
