import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = { logged: null };

const logoutSlice = createSlice({
  name: "plugiSlice",
  initialState,
  reducers: {
    logout(state) {
      debugger;
      state.logged = false;
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_preferences");
      /*   localStorage.removeItem("from"); */
    },
  },
});
export const { logout } = logoutSlice.actions;

export default logoutSlice.reducer;
